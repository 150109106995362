import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "../styles/pages/paintingtools.module.css"

const imgpc = "../images/paintingtools/pc.jpg"
const imgcyp = "../images/paintingtools/cyp.jpg"


const imgneedahand1 = "../images/home/needahand1.jpg"
const imgneedahand2 = "../images/home/needahand2.jpg"


const PaintingToolsPage = () => (
	<Layout activePath='paintingtools' pageTitle="Painting Tools" pageDescription="Stop the guesswork. Get help from these free-to-use tools. Paint Calculator estimates how much paint you need to cover your surface and Choose Your Paint recommend BOYSEN products for your specific project.">
		<h1 className={"text-centered textcolor-primary "}>Painting Tools</h1>
		<div className={"text-centered textcolor-primary "+styles.description}>
			<div>Stop the guesswork. <span className={styles.descriptionmobilebreak}>Get help from these free-to-use tools.</span></div>
			<div>Paint Calculator estimates how much paint you need to cover your surface and</div> <div>Choose Your Paint recommend <span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span> products for your specific project.</div>
		</div>
		<div className={"fullblock-desktop-only "+styles.links}>
			<Link to="/paintingtools/calculator">
				<StaticImage layout={"fullWidth"}  src={imgpc} alt={"Paint Calculator"} />
			</Link>
			<Link to="/paintingtools/choosepaint">
				<StaticImage layout={"fullWidth"}  src={imgcyp} alt={"Choose Your Paint"} />
			</Link>
		</div>

		<div className={"fullblock-mobile-only "+styles.links}>
			<Link to="/paintingtools/calculator">
				<StaticImage layout={"fullWidth"}  src={imgneedahand1} alt={"Paint Calculator"} />
			</Link>
			<Link to="/paintingtools/choosepaint">
				<StaticImage layout={"fullWidth"}  src={imgneedahand2} alt={"Choose Your Paint"} />
			</Link>
		</div>

	</Layout>
)

export default PaintingToolsPage
